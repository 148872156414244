@import "../../../breakpoints.scss";

.selector {
    display: flex;
    flex-direction: row;
    gap: 50px;

    @media only screen and (max-width: $mobile-breakpoint) {
        flex-direction: column;
        width: 100%;
        align-items: center;
        gap: 30px;
    }
}
