@import "../../../colors.scss";
@import "../../../breakpoints.scss";

$gap: 50px;
$mobile-gap: 40px;
$width: 10px;
$mobile-width: 8px;

@mixin finger-string($i) {
    left: $gap * $i - 15px;

    @media only screen and (max-width: $mobile-breakpoint) {
        left: $mobile-gap * $i - 15px;
    }
}

@mixin finger-fret($i) {
    top: $gap * 2 * $i - ($width * 6.5);

    @media only screen and (max-width: $mobile-breakpoint) {
        top: $mobile-gap * 2 * $i - ($mobile-width * 6.5);
    }
}

@mixin string($i) {
    left: $gap * $i;

    @media only screen and (max-width: $mobile-breakpoint) {
        left: $mobile-gap * $i;
    }
}

@mixin fret($i) {
    top: ($gap * 2) * $i;

    @media only screen and (max-width: $mobile-breakpoint) {
        top: $mobile-gap * 2 * $i;
    }
}

.chord-view {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1500px;

    .chord {
        position: relative;
        width: ($gap * 5) + $width;
        height: ($gap * 10) + $width;
        margin: 0 $gap $gap $gap;

        @media only screen and (max-width: $mobile-breakpoint) {
            width: ($mobile-gap * 5) + $mobile-width;
            height: ($mobile-gap * 10) + $mobile-width;
            margin: 0 $mobile-gap / 2 $mobile-gap $mobile-gap / 2;
        }

        .finger {
            animation-duration: 0.5s;
            animation-name: fadein;
        }

        @keyframes fadein {
            from {
                opacity: 0;
            }

            to {
                opacity: 1;
            }
        }

        .string,
        .fret,
        .finger {
            background: $light;
        }

        .string {
            width: $width;
            height: $gap * 10;
            top: 0;
            position: absolute;

            @media only screen and (max-width: $mobile-breakpoint) {
                width: $mobile-width;
                height: $mobile-gap * 10;
            }
        }

        .fret {
            width: $gap * 5 + $width;
            height: $width;
            top: 0;
            position: absolute;
            box-shadow: 0 5px 5px rgba(5, 5, 5, 0.157);

            @media only screen and (max-width: $mobile-breakpoint) {
                width: $mobile-gap * 5 + $mobile-width;
                height: $mobile-width;
            }
        }

        .finger {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: $width * 4;
            width: $width * 4;
            border-radius: 50%;
            position: absolute;
            box-shadow: 0 5px 5px rgba(5, 5, 5, 0.527);

            h3 {
                margin: 0;
            }

            @media only screen and (max-width: $mobile-breakpoint) {
                height: $mobile-width * 4;
                width: $mobile-width * 4;

                h3 {
                    font-size: 22px;
                }
            }
        }

        .string-1 {
            @include string(0);
        }

        .string-2 {
            @include string(1);
        }

        .string-3 {
            @include string(2);
        }

        .string-4 {
            @include string(3);
        }

        .string-5 {
            @include string(4);
        }

        .string-6 {
            @include string(5);
        }

        .fret-1 {
            @include fret(0);
        }

        .fret-2 {
            @include fret(1);
        }

        .fret-3 {
            @include fret(2);
        }

        .fret-4 {
            @include fret(3);
        }

        .fret-5 {
            @include fret(4);
        }

        .fret-6 {
            @include fret(5);
            box-shadow: 0 10px 10px rgba(5, 5, 5, 0.993);
        }

        .finger-fret-1 {
            @include finger-fret(1);
        }

        .finger-fret-2 {
            @include finger-fret(2);
        }

        .finger-fret-3 {
            @include finger-fret(3);
        }

        .finger-fret-4 {
            @include finger-fret(4);
        }

        .finger-fret-5 {
            @include finger-fret(5);
        }

        .finger-string-1 {
            @include finger-string(0);
        }

        .finger-string-2 {
            @include finger-string(1);
        }

        .finger-string-3 {
            @include finger-string(2);
        }

        .finger-string-4 {
            @include finger-string(3);
        }

        .finger-string-5 {
            @include finger-string(4);
        }

        .finger-string-6 {
            @include finger-string(5);
        }
    }
}
