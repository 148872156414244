@import "./breakpoints.scss";
@import "./colors.scss";

.App {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 50px;
    align-items: center;
    padding: 0 20px;

    @media only screen and (max-width: $mobile-breakpoint) {
        gap: 25px;
    }

    h1 {
        color: $light;
        font-size: 70px !important;
        text-decoration: underline;
        text-shadow: 0 5px 5px rgba(5, 5, 5, 0.616);
        text-align: center;
        margin: 0 !important;

        @media only screen and (max-width: $mobile-breakpoint) {
            font-size: 50px !important;
        }
    }
}
